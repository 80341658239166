import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import WindowSize from '../../services/WindowSize';
import UserProfileHeader from '../UserProfileHeader/index';
import UserCache from '../../services/cache/UserCache';
import LogicBaseAppSettings from '../LogicBaseAppSettings';
import OpenAI from '../../services/OpenAI';
import timeAgo from '../../utils/timeAgo';
import ClickableDiv from '../ClickableDiv';

const Wrapper = styled.div`
  display: flex;
`;

const App = styled.div`
  width: 100%;
  border-radius: 1rem;
  cursor: pointer;
  > div:last-child {
    width: 100%;
    > button {
      width: 3rem;
      color: white;
      text-align: center;
      margin-right: 1rem;
      font-size: ${Constants.SmallFontSize};
      i {
        padding-right: 0.25rem;
      }
    }
  }
  .hideuntilhover i {
    opacity: 0;
  }
  .hideuntilhover i:focus {
    opacity: 1;
  }
  :hover {
    .hideuntilhover i {
      opacity: 1;
    }
  }
`;
const AppSort = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;
const AppImage = styled.div`
  width: 100%;
  padding-bottom: 56%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1rem;
  overflow: hidden;
`
const Title = styled.div`
  font-size: 1.6rem;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
`;
const Description = styled.div`
  margin: 0.5rem auto;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: box;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
`;
const TitleDescription = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

export default class LogicBaseAppList extends React.PureComponent {
  static propTypes = {
    apps: PropTypes.array,
    showUser: PropTypes.bool,
    listView: PropTypes.bool,
    medium: PropTypes.bool,
    small: PropTypes.bool,
    appCollection: PropTypes.string,
    collection: PropTypes.bool,
    allowSorting: PropTypes.bool,
    showTime: PropTypes.bool,
    usage: PropTypes.bool,
    horizontal: PropTypes.bool
  }
  state = {
    menuOpen: false,
    usersLoaded: null,
    q: Navigation.currentLocation[1] === 'search' && Navigation.currentLocation[2] && unescape(Navigation.currentLocation[2]) || Navigation.currentSearch.q || '',
  }
  componentDidMount() {
    UserCache.onStateUpdate(this);
    WindowSize.onStateUpdate(this);
    window.addEventListener('resize', this.resize);
  }
  componentWillUnmount() {
    UserCache.offStateUpdate(this);
    WindowSize.offStateUpdate(this);
    window.removeEventListener('resize', this.resize);
  }
  resize = () => this.forceUpdate();
  create = () => {
    const { q } = this.state;
    OpenAI.createQuestion({ question: q });
  }
  render() {
    const { mobile } = WindowSize;
    const { apps, showUser, listView, small, medium, collection, appCollection, allowSorting, showTime, usage, horizontal } = this.props;
    const users = UserCache.getUsersByIds(apps.map(({ owner }) => owner));
    if (users) this.state.usersLoaded = users;
    const { usersLoaded, q } = this.state;
    return (
      <Wrapper style={{ flexWrap: !horizontal && 'wrap', flexDirection: listView && 'column', gap: small && listView && '1rem' || horizontal && medium && '1rem' || medium && '1.5rem' || (listView || mobile) && '2rem' || '3rem 2rem' }}>
        {q && <div onClick={this.create} style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', border: '1.5px dashed darkgray', borderRadius: '1rem', marginBottom: listView ? 'unset' : '1rem', padding: '1rem', background: '#f5f5f5' }}>
          <div style={{ position: 'relative' }}>
            <ClickableDiv onClick={this.create}>
              <h2 style={{ fontSize: '4rem', margin: 0 }}>🤖</h2>
              <TitleDescription>
                <div>
                  <Description>If you don't see your answer below, <b>click me</b> and AI Logic Bot will answer you!</Description>
                </div>
              </TitleDescription>
            </ClickableDiv>
          </div>
        </div>}
        {apps.map((app, i) => {
          const { _id, title, description, url, image, color, editors, createdTime, owner } = app;
          const appUrl = url || `apps/${_id}`;
          const onclick = () => {
            if (Constants.isWidget) {
              const params = new URLSearchParams(window.location.search);
              if (Navigation.currentSearch.collection) params.set('collection', Navigation.currentSearch.collection);
              const newURL = `/${appUrl}?${params.toString()}`;
              Navigation.navigate(newURL);
            } else {
              const relativeUrl = Navigation.currentSearch.collection && `/${appUrl}?collection=${Navigation.currentSearch.collection}`
                || appCollection && `/${appUrl}?collection=${appCollection}`
                || `/${appUrl}`;
              if (!Constants.isLogicWiki) {
                Navigation.site(collection && `https://logictry.com/collections/${appUrl}` || `https://logictry.com${relativeUrl}`);
              } else {
                if (Constants.isIphone && !["search", "collections", "account", "login", "register", "reset-password", "users"].includes(Navigation.currentLocation[1])) Navigation.replace(collection && `${Navigation.collections}/${appUrl}` || relativeUrl);
                else Navigation.push(collection && `${Navigation.collections}/${appUrl}` || relativeUrl);
              }
            }
          }
          const userLoaded = usersLoaded && usersLoaded[i];
          if (showUser && !userLoaded) return null;
          const isOpenAI = owner === '64456bf26149ef0a37502e11';
          const backgroundImage = image && `url("${image}") center / cover no-repeat`
            || isOpenAI && 'url("https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/8696c4c44913ae4ad20349c81de3987a/gradient_card_1.webp") center / cover no-repeat'
            || null;
          const outerStyle = { display: 'flex', flexDirection: listView && 'row' || 'column', flexWrap: listView && !small && window.innerWidth < 600 && 'wrap', gap: (small || medium) && listView && '0.75rem' || (small || medium) && '0' || listView && (window.innerWidth < 600 && '0.5rem' || '2rem'), flex: (small || medium) && 0 };
          const innerStyle = { color: 'white', fontWeight: 500, position: 'relative', background: backgroundImage || color || 'linear-gradient(45deg, #1ebd53, #4edc82)' || null, backgroundPosition: 'cover', height: medium && 140 || small && 80 || listView && 155, width: medium && 252 || small && 144 || listView && 280, paddingBottom: listView && 'unset', flexShrink: listView && 0, borderRadius: (small || medium) && '0.5rem' || '1rem', marginBottom: !listView && !(small || medium) && '1rem' }
          const contributors = collection && editors && UserCache.getUsersByIds(editors) || [];
          return (
            <App key={appUrl} style={outerStyle}>
              {allowSorting && <AppSort>
                {i > 0 && <i className="fas fa-arrow-up" style={{ padding: '0.5rem' }} onClick={() => { apps.splice(i, 1); apps.splice(i - 1, 0, app); this.forceUpdate(); }}></i>}
                {i < apps.length - 1 && <i className="fas fa-arrow-down" style={{ padding: '0.5rem' }} onClick={() => { apps.splice(i, 1); apps.splice(i + 1, 0, app); this.forceUpdate(); }}></i>}
              </AppSort>}
              <AppImage style={innerStyle} onClick={onclick}>
                {!image && <div style={{ position: 'absolute', inset: (small || medium) ? '0.5rem' : '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', overflow: 'hidden', textAlign: 'center' }}>
                  <div style={{ fontSize: (small) && Constants.VerySmallFontSize || medium && Constants.SmallFontSize || Constants.NormalFontSize, WebkitLineClamp: (small || medium) ? '4' : null, WebkitBoxOrient: (small || medium) ? 'vertical' : null, textOverflow: 'ellipsis', display: (small || medium) ? '-webkit-box' : null, overflow: 'hidden' }}>{title && (title.charAt(0).toUpperCase() + title.slice(1))}</div>
                </div>}
              </AppImage>
              <div style={{ position: 'relative', paddingRight: 28 }}>
                <TitleDescription onClick={onclick}>
                  <div>
                    <Title style={{ fontSize: (small || medium) && Constants.SmallFontSize || Constants.SemiSmallFontSize, fontWeight: 500 }}>{title && (title.charAt(0).toUpperCase() + title.slice(1))}</Title>
                    {(listView && !(small || medium) || collection) && <Description>{description || 'No Description'}</Description>}
                    {showTime && timeAgo(new Date(createdTime))}
                  </div>
                  {!collection && <div style={{ position: 'absolute', top: 0, right: -12 }} className={!mobile && "hideuntilhover" || null}>
                    <LogicBaseAppSettings app={app} />
                  </div>}
                </TitleDescription>
                {!collection && showUser && usersLoaded && <div style={{ display: 'flex', gap: '0.5rem', margin: '0 0 0', color: Constants.LightTextColor }}>
                  {([usersLoaded[i], ...contributors].map((user) => (
                    <UserProfileHeader
                      key={user && user._id}
                      user={user}
                      small
                      hideFollow
                      nameOnly
                    />
                  )))}
                </div>}
              </div>
              {usage && usage[i] && <div style={{ fontSize: Constants.SemiSmallFontSize, textAlign: 'right' }}>{usage[i].users} users - {formatTime(usage[i].time)}</div>}
            </App>
          )
        })}
      </Wrapper>
    );
  }
}function formatTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds % 3600) / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  return `${hours} hr ${minutes} min ${remainingSeconds} sec`;
}