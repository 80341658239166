import React from 'react';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import Navigation from '../../services/Navigation';
import { PropTypes } from 'prop-types';
import ScrollIt from '../../utils/Scroll';
import Scrollable from '../Scrollable';
import WindowSize from '../../services/WindowSize';

const Topic = styled.div`
  cursor: pointer;
  min-width: 4rem;
  text-align: center;
  border-radius: 1.5rem;
  font-size: 0.75rem;
  flex-shrink: 0;
  padding: 0.5rem 1rem;
`;
const Arrows = styled.div`
  > div {
    position: absolute;
    height: 34px;
    width: 34px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > div {
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      box-shadow: 0px 0px 0px 1px transparent, 0px 0px 0px 4px transparent, 0px 6px 16px rgb(0 0 0 / 12%) !important;
    }
  }

  > div:first-child {
    left: 0;
  }
  > div:last-child {
    right: 0;
  }
`;
export default class LogicBaseCollectionTopics extends React.PureComponent {
  static propTypes = {
    row: PropTypes.bool,
    url: PropTypes.string,
  }
  constructor() {
    super();
    this.state = {
      showLeft: false,
      showRight: false,
    }
  }
  async componentDidMount() {
    WindowSize.onStateUpdate(this);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
  }
  scroll = (forward) => {
    if (!this.topicsRef) return;
    const destination = Math.max(this.topicsRef.scrollLeft + (forward ? this.topicsRef.offsetWidth : -this.topicsRef.offsetWidth), 0);
    ScrollIt(destination, this.topicsRef, undefined, undefined, undefined, true);
  }
  navigateTag = (tag) => {
    const { url } = this.props;
    if (!tag || tag === 'latest') return Navigation.push(`${url}`);
    if (Constants.isIphone) return Navigation.push(`/search/topic/${tag}`);
    return Navigation.push(`${url}?t=${tag}`, { 'logicbasecollectiontopics_horizontal': this.topicsRef.scrollLeft });
  }
  onScroll = () => {
    if (!this.topicsRef) return;
    const showLeft = this.topicsRef.scrollLeft > 0;
    const showRight = Math.ceil(this.topicsRef.scrollLeft + this.topicsRef.offsetWidth) < this.topicsRef.scrollWidth;
    this.setState({ showLeft, showRight });
  }
  render() {
    const { row } = this.props;
    const { showLeft, showRight } = this.state;
    const t = Constants.isIphone ? Navigation.currentLocation[3] : Navigation.currentSearch.t || 'latest';
    const tagArray = t && t.split(',') || [];
    const topLikedTopics = [
      { name: 'Latest', url: 'latest' },
      { name: 'Trending', url: 'trending' },
      { name: 'politics', url: 'politics' },
      { name: 'shopping', url: 'shopping' },
      { name: 'startups', url: 'startups' }
    ];
    return (
      <div style={{ position: 'relative', overflow: 'hidden', height: 34 }}>
        <Scrollable
          horizontal
          onScroll={this.onScroll}
          scrollRef={(e) => { this.topicsRef = e; }}
          style={{ position: 'absolute', inset: 0 }}
          rememberScrollPosition={`logicbasecollectiontopics`}
        >
          <div style={{ display: 'flex', gap: '1rem', flexWrap: row ? null : 'wrap', justifyContent: row ? null : 'center', alignItems: row ? 'center' : null }}>
            {topLikedTopics.map(({ name, url }) => {
              const isSelected = tagArray.includes(url);
              return (
                <Topic key={url} style={{ backgroundColor: isSelected ? Constants.DarkGray : 'rgba(242, 242, 242, 1)', color: isSelected ? 'white' : 'unset', }} onClick={() => this.navigateTag(url)}>{url}</Topic>
              )
            })}
          </div>
        </Scrollable>
        {row && <Arrows>
          <div style={{ display: showLeft && 'flex' || 'none' }}>
            <div onClick={() => this.scroll(false)}>
              <i className="fas fa-angle-left"></i>
            </div>
          </div>
          <div style={{ display: showRight && 'flex' || 'none' }}>
            <div onClick={() => this.scroll(true)}>
              <i className="fas fa-angle-right"></i>
            </div>
          </div>
        </Arrows>}
      </div>
    );
  }
}
