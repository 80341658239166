import React from 'react';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import Constants from '../../submodules/logictry_config/constants';
import UserAccount from '../../services/UserAccount';
import OpenAI from '../../services/OpenAI';
import StatefulButton from '../../components/StatefulButton';
import ConfirmDialog from '../../components/ConfirmDialog';
import Scrollable from '../../components/Scrollable';
import { FONTS } from '../../utils/fonts';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent';

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Form = styled.form`
  padding: 0 1rem;
  max-width: 32rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 1rem;
    text-align: center;
  }
  ol {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  input[type="text"] {
    border-bottom: 1px solid black;
    width: 100%;
  }
`;

export default class LogicBaseAppCreatePage extends React.PureComponent {
  state = {
    warning: '',
    error: '',
    errorDescription: '',
  }
  create = () => {
    const question = document.getElementById('CREATEPAGE__QUESTION').value;
    const backgroundColor = document.getElementById('CREATEPAGE__BACKGROUNDCOLOR').value;
    const primaryColor = document.getElementById('CREATEPAGE__PRIMARYCOLOR').value;
    const website = document.getElementById('CREATEPAGE__WEBSITE').value || 'amazon.com';
    const affiliateCode = document.getElementById('CREATEPAGE__AFFILIATE').value;
    const font = document.getElementById('CREATEPAGE__FONT').value;
    const { account } = UserAccount;
    const { _id, username } = account;
    if (!question) return this.setState({ error: 'Question Blank', errorDescription: 'You must fill out a question to ask.' });
    if (!username) return this.setState({ error: 'Username Missing', errorDescription: 'You must create a username first.  Go to your Account -> Settings, change your username, and click save.' });
    OpenAI.createQuestion({ question, owner: { _id, username }, backgroundColor, primaryColor, website, affiliateCode, font });
  }
  render() {
    if (!UserAccount.account.username) return <FullScreenVerticalCenterContent>
      <h1>Update Username Required</h1>
      <p>You must update your username before you can use this feature.</p>
      <p>Please go to your account settings and change your username.</p>
    </FullScreenVerticalCenterContent>
    return (
      <Scrollable
        vertical
        style={{ position: 'absolute', inset: 0 }}
      >
        <Wrapper>
          <Form>
            <h1>Create AI Powered Decision Guide</h1>
            <ol>
              <li>
                <h3>{`Create a question and be specific (e.g. "What TimeX watch should I buy for my son to go hiking?")`}</h3>
                <input id="CREATEPAGE__QUESTION" type="text"></input>
              </li>
              <li>
                <h3>{`Select a background color`}</h3>
                <input id="CREATEPAGE__BACKGROUNDCOLOR" type="color"></input>
              </li>
              <li>
                <h3>{`Select a button color`}</h3>
                <input id="CREATEPAGE__PRIMARYCOLOR" type="color"></input>
              </li>
              <li>
                <h3>{`Select a font`}</h3>
                <select id="CREATEPAGE__FONT" defaultValue={'Montserrat'}>
                  {FONTS.map((font) => (
                    <option key={font} value={font} style={{ fontFamily: font }}>
                      {font}
                    </option>
                  ))}
                </select>
              </li>
              <li>
                <h3>{`Specify a website to purchase the product (e.g. "amazon.com")`}</h3>
                <input id="CREATEPAGE__WEBSITE" type="text" defaultValue="amazon.com"></input>
              </li>
              <li>
                <h3>{`OPTIONAL: Include your affiliate code`}</h3>
                <input id="CREATEPAGE__AFFILIATE" type="text"></input>
              </li>
            </ol>
            <div style={{ margin: '2rem 0' }}>
              <StatefulButton
                onClick={this.create}
                text="Submit"
                warning={this.state.warning}
                filledColor
              />
            </div>
            <ConfirmDialog
              open={!!this.state.error}
              text={this.state.error || ''}
              description={this.state.errorDescription}
              onOk={() => this.setState({ error: null, errorDescription: null })}
            />
          </Form>
        </Wrapper>
      </Scrollable>
    )
  }
}
