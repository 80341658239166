/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import Constants from '../../submodules/logictry_config/constants';
import SidebarService from '../../services/SidebarService';
import Navigation from '../../services/Navigation';
import GrayOut from '../../services/TreeDisplay';
import SessionManager from '../../services/SessionManager';
import UserAccount from '../../services/UserAccount';
import Company from '../../services/Company';
import AutoSaveTree from '../../services/AutoSaveTree';
import WindowSize from '../../services/WindowSize';
import Connect from '../../services/Connect';
import TreeCache from '../../services/cache/TreeCache';
import FullScreenVerticalCenterContent from '../../styledhtml/FullScreenVerticalCenterContent/index';
import TreeAnswerBox from './TreeAnswerBox';
import DrawerBreadcrumb from '../../components/DrawerBreadcrumb';
import SaveTreeAndSubtrees from '../../utils/TreeParsers/SaveTreeAndSubtrees';
import Sidebar from './Sidebar';
import TextView from './TextView';
import LoggedOutSavePopup from './LoggedOutSavePopup';
import ClickableIcon from '../../components/ClickableIcon/index';
import { findForceIframeContent } from '../../models/nodetypes';
import CreateProject from '../../services/CreateProject';
import { PropTypes } from 'prop-types';
import UserNotAuthorizedPopup from '../../components/UserNotAuthorizedPopup/index';
import LogicBaseAppCache from '../../services/cache/LogicBaseAppCache';
import StatefulTextField from '../../components/StatefulTextField/index';
import StatefulButton from '../../components/StatefulButton/index';
import ChatInterface from '../../components/ObjectSettings/ChatInterface';
import { validateEmail } from '../../utils/regex';
import { loadFont } from '../../utils/fonts';
import EmbedFullscreenButton from '../../components/EmbedFullscreenButton';

const Wrapper = styled.div`
  h1 {
    font-size: ${props => props.fontSizeH1};
  }
  h2 {
    font-size: ${props => props.fontSizeH2};
  }
  h3 {
    font-size: ${props => props.fontSizeH3};
  }
`;
const ContentWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;
const EditingDisabled = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  color: white;
  background-color: rgba(0,0,0,0.08);
  > div {
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 4px 0px;
    background-color: ${Constants.PrimaryColor};
    position: absolute;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 2rem;
    line-height: 2rem;
    > div {
      color: white;
      font-weight: bold;
      font-size: ${Constants.SemiSmallFontSize};
    }
  }
`;
const FullscreenWrapper = styled.div`
  position: fixed;
  top: 8px;
  z-index: 4;
  background-color: ${Constants.White};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.65);
`;

const companyId = window.logictry && window.logictry.company && window.logictry.company._id;
const website = window.logictry && window.logictry.company && window.logictry.company.website;
const publicHome = window.logictry && window.logictry.company && window.logictry.company.publicHome;
const originalUrl = window.location.href.replace(window.location.origin, '');
let backgroundColor;
let backgroundColorTimeout;

export default class TreeAnswerPage extends React.PureComponent {
  static propTypes = {
    fullscreen: PropTypes.bool,
    showAppId: PropTypes.string,
    onScroll: PropTypes.func,
  }
  constructor(props) {
    super(props);
    SidebarService.updateSelectType(Navigation.currentSearch.showchatbot === 'true' ? 'Chat' : 'Tree');
    Connect.__iframe = document.createElement('iframe');
    Connect.__iframe.style.width = '100%';
    Connect.__iframe.style.height = '100%';
    Connect.__iframe.style.border = '0px';
  }
  state = {
    autocreating: false,
    submitEmail: false,
    skipToReport: false,
    loading: true,
    password: '',
  }
  componentDidMount() {
    WindowSize.onStateUpdate(this);
    Connect.onStateUpdate(this);
    SidebarService.onStateUpdate(this.updateState);
    TreeCache.onStateUpdate(this.treeCacheUpdated);
    UserAccount.onStateUpdate(this.treeCacheUpdated);
    LogicBaseAppCache.onStateUpdate(this.treeCacheUpdated);
    this.treeCacheUpdated();
    clearTimeout(backgroundColorTimeout);
  }
  componentWillUnmount() {
    WindowSize.offStateUpdate(this);
    Connect.offStateUpdate(this);
    SidebarService.offStateUpdate(this.updateState);
    TreeCache.offStateUpdate(this.treeCacheUpdated);
    UserAccount.offStateUpdate(this.treeCacheUpdated);
    LogicBaseAppCache.offStateUpdate(this.treeCacheUpdated);
    const { tree } = this.state;
    if (tree && tree._id) {
      tree.offStateUpdate(this.treeUpdated);
      SessionManager.clearAppActive(tree._id);
    }
    backgroundColorTimeout = setTimeout(() => { backgroundColor = null; }, 1000);
  }
  handleClick = () => {
    const { isWidget } = Constants;
    if (!isWidget) return;
    if (Navigation.currentSearch.onclickshowfullscreen !== 'true') return;
    if (Connect.isFullscreen) return;
    Connect.toggleFullscreen();
  }
  get treeIdToGet() {
    const { showAppId } = this.props;
    if (showAppId) return showAppId;
    if (Navigation.currentLocation[1] === 'apps' && Navigation.currentLocation[2]) return Navigation.currentLocation[2];
    if (UserAccount.isLoggedIn && Navigation.currentLocation[1] === '') {
      const userSubscription = Company.company && Company.company.subscriptions && Company.company.subscriptions[0] && Company.company.subscriptions[0].users && Company.company.subscriptions[0].users.find(({ _id }) => _id === UserAccount.account._id);
      const userSubscriptionHome = userSubscription && userSubscription.home && userSubscription.home._id;
      return UserAccount.account.home
        && (Company.isExpected ? UserAccount.account.home[companyId] : UserAccount.account.home._id)
        || Company.isExpected && (userSubscriptionHome || Company.company.home && Company.company.home._id)
        || publicHome && publicHome._id
        || '635aa33615ca2c624d5e3e13';
    }
    if (UserAccount.isLoggedIn && Navigation.currentLocation[1].toLowerCase() === 'company') {
      return Company.company.home && Company.company.home._id || '635aa33615ca2c624d5e3e13';
    }
    if (Constants.isLogicWiki && Navigation.currentLocation[1]) {
      const publicApps = LogicBaseAppCache.query({ u: Navigation.currentLocation[1] });
      if (publicApps && publicApps[0] && publicApps[0]._id) return publicApps[0]._id;
    }
    if (Company.isExpected && !UserAccount.isLoggedIn) {
      const websiteLocation = website && window.location.pathname.split('/').slice(1).reduce((prev, curr) => (prev && curr) ? prev[curr] : prev, website);
      if (websiteLocation && websiteLocation._id) {
        if (websiteLocation._id.startsWith('http')) {
          window.location.reload();
        }
        return websiteLocation._id;
      }
    }
    return null;
  }
  treeCacheUpdated = () => {
    const { isLoggedIn, account } = UserAccount;
    const { isWidget, apikey } = Constants;
    const { currentSearch } = Navigation;
    const { password, pwd } = currentSearch;
    let { tree } = this.state;
    const { treeIdToGet } = this;
    if (tree && tree._id) tree.offStateUpdate(this.treeUpdated);
    if (!treeIdToGet || treeIdToGet.startsWith('http')) return this.forceUpdate();
    tree = TreeCache.getTree(treeIdToGet, null, null, null, password || pwd, isWidget && apikey);
    if (!tree || tree.error) return this.setState({ tree, autocreating: false });
    if (tree.project && this.state.tree !== tree) {
      if (Object.hasOwnProperty.call(currentSearch, 'autocreate')) {
        Navigation.getQueryParameter('autocreate');
      } else if (tree.progress && tree.progress.report || !(isLoggedIn && account._id === tree.owner)) {
        this.state.skipToReport = true;
      }
    } else if (tree.template && this.state.tree !== tree) {
      CreateProject.initialize();
    }
    this.state.tree = tree;
    GrayOut.setTree(tree);
    SessionManager.setAppActive(tree && tree._id);
    tree.onStateUpdate(this.treeUpdated);

    if (tree.root && tree.root.fontFamily) {
      loadFont(tree.root.fontFamily)
    }

    // Check if autocreate exists
    if (tree.template && Object.hasOwnProperty.call(currentSearch, 'autocreate') && !this.state.autocreating) {
      this.state.autocreating = true;
      this.saveChanges();
      return this.forceUpdate();
    }

    this.state.autocreating = false;
    this.treeUpdated();
    return this.forceUpdate();
  }
  saveProgress = () => {
    SidebarService.updateSelectType('Email', true);
  }
  saveChanges = (email) => {
    const { selectType } = SidebarService;
    const { team } = Navigation.currentSearch;
    const { tree, autocreating } = this.state;
    const { isSavingProjectsAllowed } = tree;
    if (team) tree.addTeam(team);
    if (tree.type === 'Project') {
      SaveTreeAndSubtrees(tree);
    } else if (!isSavingProjectsAllowed) {
      SidebarService.updateSelectType('Text');
    } else if (UserAccount.isLoggedIn) {
      if (!autocreating && selectType === 'Text') tree.reportHasBeenViewed();
      CreateProject.createProject(tree, team);
    } else if (email && !validateEmail(email)) {
      if (!autocreating && selectType === 'Text') tree.reportHasBeenViewed();
      CreateProject.registerAndCreateProject(tree, email, team);
    } else {
      this.setState({ submitEmail: true });
    }
  }
  updateState = () => {
    const { isLoggedIn } = UserAccount;
    const { selectType, saveOnShowReport } = SidebarService;
    const { skipToReport, loading } = this.state;
    this.state.selectType = selectType;
    Connect.sendIframeMessage({
      selectType,
    });
    const { tree } = GrayOut;
    if (!tree) return null;
    const { project } = tree;
    if (project && !loading && isLoggedIn) {
      if (selectType === 'Tree') tree.clearReportHasBeenViewed();
      else if (selectType === 'Text') tree.reportHasBeenViewed();
    }
    if (skipToReport && selectType === 'Text') {
      this.state.skipToReport = false;
    }
    if (selectType === 'Email' && saveOnShowReport) {
      if (isLoggedIn) {
        SidebarService.updateSelectType('Tree', true);
        this.saveChanges();
      } else {
        const email = tree.getReportSaveOnSubmitEmail();
        return this.saveChanges(email);
      }
    }
    if (selectType === 'Text' && saveOnShowReport) {
      const email = tree.getReportSaveOnSubmitEmail();
      return this.saveChanges(email);
    }
    return this.forceUpdate();
  }
  treeUpdated = () => {
    const { tree, loading, skipToReport } = this.state;
    if (!tree) return;
    AutoSaveTree.autoSaveProject(tree);

    if (loading) {
      if (tree.isBusy) {
        clearTimeout(this.waitTillLoadedTimeout);
        this.waitTillLoadedTimeout = null;
      } else if (!this.waitTillLoadedTimeout) {
        this.waitTillLoadedTimeout = setTimeout(() => {
          if (this.state.skipToReport) SidebarService.updateSelectType('Text');
          this.setState({ loading: false });
        }, skipToReport ? 1000 : 200);
      }
    }

    // Check for query parameters
    if (this.__checkQueryParametersTimeout) clearTimeout(this.__checkQueryParametersTimeout);
    this.__checkQueryParametersTimeout = setTimeout(() => {
      this.__checkQueryParametersTimeout = null;
      this.__checkQueryParameters();
    }, 100);
  }
  __checkQueryParameters = () => {
    const { tree } = this.state;
    if (!tree) return;
    const queryString = Navigation.currentSearch;
    const foundParameters = tree.updateQueryParameters(queryString);
    Array.from(foundParameters).forEach((p) => Navigation.getQueryParameter(p));
  }
  toggleFullscreen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Connect.toggleFullscreen();
  }
  onRef = (e) => {
    if (!e || !Connect.__iframe) return;
    const { tree } = this.state;
    const { customSource } = tree;
    Connect.__iframe.src = customSource && customSource.url || `${window.location.origin}/apps/${tree._id}${window.location.search}`;
    e.appendChild(Connect.__iframe);
  }
  loggedOutSavePopupClosed = (success) => {
    const { saveOnShowReport } = SidebarService;
    const { tree, submitEmail } = this.state;
    const { isSavingProjectsAllowed } = tree;
    const goBackToForm = isSavingProjectsAllowed && saveOnShowReport && submitEmail;
    if (!success && goBackToForm) SidebarService.updateSelectType('Tree');
    return this.setState({ submitEmail: false });
  }
  checkpassword = () => {
    const url = new URL(window.location.href);
    url.searchParams.set('password', this.state.password);
    window.location = url.href;
  }
  successOK = () => this.setState({ submitEmail: false });
  render() {
    const { fullscreen, onScroll } = this.props;
    const { isApp, isWidget, isWidgetOnSameDomain } = Constants;
    const { isAuthorizedToLogIn } = Company;
    const { isLoggedIn } = UserAccount;
    const { mobile } = WindowSize;
    const { selectType, saveOnShowReport } = SidebarService;
    const { autocreating, tree, submitEmail, loading } = this.state;

    const showPreviousBackground = !Constants.isLogicWiki && backgroundColor;
    if (!Constants.isLogicWiki && !this.treeIdToGet) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget}><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    if (!tree || tree.loading) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={showPreviousBackground || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    if (tree.error) {
      if (tree.error === 'PasswordIncorrect') return (
        <FullScreenVerticalCenterContent>
          <h1 style={{ marginBottom: '2rem' }}>Enter Password</h1>
          <StatefulTextField
            value={this.state.password}
            onChange={(password) => { this.setState({ password }); }}
            type="password"
            style={{ minWidth: 120, maxWidth: '80%', width: 400 }}
            label={'Password'}
            onKeyDown={(e) => { if (e.key === 'Enter') { this.checkpassword(); } }}
          />
          <div style={{ margin: 40 }}>
            <StatefulButton
              onClick={this.checkpassword}
              text={'Submit'}
              warning={this.state.warning}
              state=""
            />
          </div>
        </FullScreenVerticalCenterContent>
      );
      return <FullScreenVerticalCenterContent><h1>App Not Found</h1></FullScreenVerticalCenterContent>;
    }

    if (!Connect.isFullscreen && Navigation.currentSearch.showplaceholder === 'true') {
      return <div style={{ cursor: 'pointer' }} onClick={this.toggleFullscreen}>
        <img alt={'placeholder'} style={{ position: 'fixed', inset: 0, width: '100%', height: '100%', objectFit: 'contain' }} src={getPlaceholderImage()}></img>
      </div>
    }

    if (!!autocreating) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={showPreviousBackground || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const { isOwnerOrAdmin, isLocked, project, isCustomApp, isSavingProjectsAllowed } = tree;
    const showSidebar = isLoggedIn && isApp && !mobile && !fullscreen && isAuthorizedToLogIn;
    const right = showSidebar ? 40 : 0;
    const runInIframe = isCustomApp || (!isWidget && (Navigation.currentSearch.iframe || findForceIframeContent(tree.root)));
    const editingVersionOfTree = TreeCache.getTree(tree._id, true);
    if (!editingVersionOfTree) return <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={showPreviousBackground || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>;
    const dontShowReportUntilSaved = isSavingProjectsAllowed && saveOnShowReport;
    const dontShowEmailUntilSaved = dontShowReportUntilSaved && submitEmail;
    const showRootProperty = (selectType === 'Tree' || (selectType === 'Text' && dontShowReportUntilSaved)) && tree.root;
    const showReportProperty = selectType === 'Text' && tree.report;
    backgroundColor = showRootProperty && tree.root.backgroundColor || showReportProperty && tree.report.backgroundColor;
    const backgroundImage = showRootProperty && tree.root.backgroundImage || showReportProperty && tree.report.backgroundImage;
    const backgroundVideo = showRootProperty && tree.root.backgroundVideo || showReportProperty && tree.report.backgroundVideo;
    const className = showRootProperty && 'logictry-root' || showReportProperty && 'logictry-report';
    const showYouAreNotAuthorizedToEdit = isLoggedIn && project && !isOwnerOrAdmin && selectType === 'Tree';
    const showLoginToEditYourSubmission = project && !isLoggedIn && selectType === 'Tree';
    const showProjectLocked = isLoggedIn && project && isOwnerOrAdmin && isLocked;
    const showDeleted = tree.isDeleted;
    const showLoginToView = !isLoggedIn && tree.shareSetting === 'LoggedInLinkView';
    const filter = showLoginToView && 'blur(6px) brightness(0.5)';
    const fontFamily = showRootProperty && tree.root.fontFamily || showReportProperty && tree.report.fontFamily;
    const fontSizeH1 = showRootProperty && tree.root.fontSizeH1 || showReportProperty && tree.report.fontSizeH1;
    const fontSizeH2 = showRootProperty && tree.root.fontSizeH2 || showReportProperty && tree.report.fontSizeH2;
    const fontSizeH3 = showRootProperty && tree.root.fontSizeH3 || showReportProperty && tree.report.fontSizeH3;
    return (
      <>
        <Wrapper
          onClick={this.handleClick}
          fontSizeH1={fontSizeH1}
          fontSizeH2={fontSizeH2}
          fontSizeH3={fontSizeH3}
          style={{ opacity: loading ? 0 : 1, fontFamily }}
          key={`${tree.key}`}
        >
          {isLoggedIn && <DrawerBreadcrumb key={tree.key}>
          </DrawerBreadcrumb>}
          {selectType === 'Chat' && (
            <ChatInterface tree={tree} style={{ filter, right }} />
          ) || runInIframe && (
            <ContentWrapper ref={this.onRef} style={{ filter, right, backgroundColor, backgroundImage: backgroundImage && `url(${backgroundImage})` }}></ContentWrapper>
          ) || (<>
            <ContentWrapper style={{ filter, right }} className={className}>
              {backgroundColor && <div style={{ position: 'absolute', inset: 0, backgroundColor }}></div>}
              {backgroundImage && <ContentWrapper style={{ position: 'absolute', inset: 0, backgroundImage: backgroundImage && `url(${backgroundImage})` }}></ContentWrapper>}
              {backgroundVideo && <video style={{ objectFit: 'cover', position: 'absolute', inset: 0, width: '100%', height: '100%' }} autoPlay muted playsInline loop src={backgroundVideo}></video>}
              {((selectType === 'Email' && !dontShowEmailUntilSaved) && (
                <FullScreenVerticalCenterContent>
                  <h1>Progress Saved</h1>
                  <p>Follow the instructions in your email to continue your submission.</p>
                </FullScreenVerticalCenterContent>
              )) || ((selectType === 'Text' && !dontShowReportUntilSaved) && (
                <TextView saveChanges={this.saveChanges} onScroll={onScroll} />
              // ) : (selectType === 'Graph') ? (
              //   <TreeGraphWrapper>
              //     <TreeGraphBox tree={tree} />
              //   </TreeGraphWrapper>
              )) || (
                <TreeAnswerBox key={tree.key} tree={tree} saveProgress={this.saveProgress} onScroll={onScroll} />
              )}
            </ContentWrapper>
          </>)}
          {showDeleted && <EditingDisabled style={{ right }}><div>{project ? 'Submission' : 'App'} has been deleted</div></EditingDisabled>
          || showYouAreNotAuthorizedToEdit && <EditingDisabled style={{ right }}><div>You are not authorized to edit this submission</div></EditingDisabled>
          || showLoginToEditYourSubmission && <EditingDisabled style={{ right }}><div>Login to edit your submission</div></EditingDisabled>
          || showProjectLocked && <EditingDisabled style={{ right }}><div>Project Locked - Unlock to edit</div></EditingDisabled>}
          {isApp && !fullscreen && <Sidebar showMini={!showSidebar} tree={editingVersionOfTree} saveChanges={this.saveChanges} />}
          {isWidget && !isWidgetOnSameDomain && originalUrl.startsWith('/collections/') && <FullscreenWrapper style={{ left: 8 }}>
            <ClickableIcon small onClick={() => Navigation.back()} icon="fas fa-arrow-left" />
          </FullscreenWrapper>}
          <EmbedFullscreenButton />
          {submitEmail && <LoggedOutSavePopup tree={tree} onClose={this.loggedOutSavePopupClosed} />}
        </Wrapper>
        {loading && <FullScreenVerticalCenterContent alignTop={Constants.isWidget} backgroundColor={showPreviousBackground || ''}><LoadingIndicator /></FullScreenVerticalCenterContent>}
        <UserNotAuthorizedPopup />
        {showLoginToView && <div
          onClick={() => Navigation.push(Navigation.login)}
          style={{ position: 'absolute', inset: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
        >
          <div style={{ color: 'white', padding: '1rem 2rem', borderRadius: '1rem', fontSize: Constants.SemiLargeFontSize, fontWeight: 'bold' }}>Login to view this content</div>
        </div>}
      </>
    );
  }
}
function getPlaceholderImage() {
  const ogImageMetaTag = document.querySelector('meta[property="og:image"]');
  const ogImageUrl = ogImageMetaTag ? ogImageMetaTag.getAttribute('content') : null;
  return ogImageUrl ? (ogImageUrl.includes('/v1/images') ? `${ogImageUrl}&width=${window.innerWidth}&height=${window.innerHeight}` : ogImageUrl) : null;
}
